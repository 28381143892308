import { useDebounce } from '@uidotdev/usehooks'
import { CopyButton, CreateButton, DeleteButton, OpenButton } from 'components/Button/Buttons'
import { DropDown, InputText } from 'components/Common/Inputs'
import Loading from 'components/Common/Loading'
import SimpleModal from 'components/Common/SimpleModal'
import Paging from 'components/Paging'
import {
  ActionIconWrapper,
  ComponentWrapper,
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table/Table'
import { TableBody } from 'components/Table/TableBody'
import { TableHead } from 'components/Table/TableHead'
import { useToastContext } from 'context/ToastContext'
import { formatDate } from 'helpers/date-helper'
import { getDataForPagination } from 'helpers/pagination'
import useAccountsList from 'hooks/useAccountsList'
import useGetCustomersList from 'hooks/useGetCustomersList'
import useLocalStorage from 'hooks/useLocalStorage'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DealService from 'services/deal.service'

const notFoundMessage = 'Keine Kampagne gefunden'

// Error and success message helpers
const errorMessage = (error) => `Technischer Fehler: ${error}`
const deleteSuccessMessage = (objectId) => `Die Kampagne (ID: ${objectId}) wurde gelöscht.`

const Index = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()

  const [modal, setModal] = useState({ show: false })
  const { accountsList, accountsLoading } = useAccountsList()
  const { customersList, customersLoading } = useGetCustomersList()

  const [pageState, setPageState] = useState({ data: [], totalResults: null, isLoading: true })

  const [selectedAccount, setSelectedAccount] = useLocalStorage('deals-selected-account', null)
  const [selectedCustomer, setSelectedCustomer] = useLocalStorage('deals-selected-customer', null)
  const [selectedPageSize, setSelectedPageSize] = useLocalStorage('deals-selected-page-size', 20)
  const [selectedPage, setSelectedPage] = useLocalStorage('deals-selected-page', 1)
  const [searchQuery, setSearchQuery] = useLocalStorage('deals-search-query', '')

  // Debounce searchQuery with a delay of 1 second
  const debouncedSearchQuery = useDebounce(searchQuery, 1000)

  const entityGroupName = 'deals'
  const entityName = 'Kampagne'

  // Fetch data on mount or when filters change, with the debounced search query
  useEffect(() => {
    fetchData()
  }, [selectedAccount, selectedCustomer, selectedPageSize, selectedPage, debouncedSearchQuery])

  const fetchData = useCallback(async () => {
    setPageState((prev) => ({ ...prev, isLoading: true }))
    try {
      const [pagingResult, deals] = await Promise.all([
        DealService.getPaging(
          selectedCustomer,
          selectedAccount,
          selectedPageSize,
          debouncedSearchQuery
        ),
        DealService.get(
          selectedCustomer,
          selectedAccount,
          selectedPageSize,
          selectedPage,
          debouncedSearchQuery
        ),
      ])

      if (!pagingResult?.count) {
        navigate(`/${entityGroupName}/`)
        addToast(
          'Es wurden keine Kampagnen gefunden. Es muss mindestens ein Kunde existieren.',
          'error'
        )
        return
      }

      const dealsWithMainAction = deals.map((deal) => ({
        ...deal,
        mainAction: deal?.actions?.find((x) => x.type === 'HV'),
      }))

      setPageState({
        data: dealsWithMainAction,
        totalResults: pagingResult.count,
        isLoading: false,
      })
    } catch (error) {
      addToast(errorMessage(error), 'error')
      setPageState((prev) => ({ ...prev, isLoading: false }))
    }
  }, [
    selectedCustomer,
    selectedAccount,
    selectedPageSize,
    selectedPage,
    navigate,
    addToast,
    debouncedSearchQuery,
  ])

  const handleDelete = useCallback(
    async (dealId) => {
      try {
        await DealService.deleteByObjectId(dealId)
        addToast(deleteSuccessMessage(dealId), 'success')
        setModal({ show: false })
        fetchData()
      } catch (error) {
        addToast(errorMessage(error), 'error')
      }
    },
    [addToast, fetchData]
  )

  const openDeleteModal = useCallback(
    (dealId, name) => {
      setModal({
        show: true,
        title: `Lösche ${entityName}`,
        text: `Willst du "${name}" wirklich löschen?`,
        onCancelClicked: () => setModal({ show: false }),
        onOkayClicked: () => handleDelete(dealId),
        submitButton: 'Ja',
        backButton: 'Nein',
      })
    },
    [handleDelete]
  )

  const copyDeal = useCallback(
    async (deal) => {
      try {
        await DealService.copyDeal(deal._id)
        fetchData()
      } catch (error) {
        addToast(errorMessage(error), 'error')
      }
    },
    [addToast, fetchData]
  )

  const isLoading = pageState.isLoading || customersLoading || accountsLoading

  if (isLoading) return <Loading />

  return (
    <>
      <ComponentWrapper>
        <div className="flex justify-center items-center">
          {customersList && (
            <CreateButton
              onClick={() => navigate(`/${entityGroupName}/create`)}
              title={`${entityName} anlegen`}
            />
          )}
        </div>
      </ComponentWrapper>

      <ComponentWrapper>
        <div className="flex">
          <div className="basis-1/4">
            <InputText
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              title="Filter Name"
            />
          </div>
          <DropdownField
            list={customersList}
            title="Filter Kunde"
            value={selectedCustomer}
            onChange={setSelectedCustomer}
          />
          <DropdownField
            list={accountsList}
            title="Filter Account"
            value={selectedAccount}
            onChange={setSelectedAccount}
          />
          <DropdownField
            list={[
              { _id: '20', name: '20' },
              { _id: '50', name: '50' },
              { _id: '100', name: '100' },
            ]}
            title="Anzahl Ergebnisse"
            value={selectedPageSize}
            onChange={setSelectedPageSize}
          />
        </div>

        <Paging
          data={getDataForPagination(
            pageState.totalResults,
            selectedPageSize,
            selectedPage,
            setSelectedPage
          )}
        />

        <Table>
          <TableHead>
            <TableHeaderRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Buchungsdatum</TableCell>
              <TableCell>1. HV</TableCell>
              <TableCell></TableCell>
            </TableHeaderRow>
          </TableHead>

          <TableBody>
            {pageState.data?.length ? (
              pageState.data.map((item, index) => (
                <TableRow key={item._id} index={index}>
                  <TableCell>{item.customer ? item.customer.name : '- gelöscht -'}</TableCell>
                  <TableCell>{item.account.name}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{formatDate(item.bookingDate)}</TableCell>
                  <TableCell>
                    {item.mainAction && (
                      <a
                        href={`./actions/${item.mainAction._id}/update`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.mainAction.type} {formatDate(item.mainAction.date)}{' '}
                        {item.mainAction.time}
                      </a>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.customer && (
                      <ActionIconWrapper>
                        <OpenButton
                          onClick={() => navigate(`/${entityGroupName}/${item._id}/update`)}
                        />
                        <CopyButton onClick={() => copyDeal(item)} />
                        <DeleteButton onClick={() => openDeleteModal(item._id, item.name)} />
                      </ActionIconWrapper>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <tr>
                <TableCell colSpan={6} className="text-center">
                  {notFoundMessage}
                </TableCell>
              </tr>
            )}
          </TableBody>
        </Table>

        <Paging
          data={getDataForPagination(
            pageState.totalResults,
            selectedPageSize,
            selectedPage,
            setSelectedPage
          )}
        />
      </ComponentWrapper>

      {modal.show && <SimpleModal modal={modal} />}
    </>
  )
}

// Reusable DropdownField component for better readability
const DropdownField = ({ list, title, value, onChange }) => (
  <div className="basis-1/4">
    <DropDown list={list} title={title} value={value} onChange={(e) => onChange(e.target.value)} />
  </div>
)

export default Index
