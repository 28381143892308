import { StatusButton } from 'components/Common/StatusButton'
import {
  ActionIconWrapper,
  ComponentWrapper,
  Table,
  TableCell,
  TableHeaderRow,
  TableRow,
} from 'components/Table/Table'
import { TableBody } from 'components/Table/TableBody'
import { TableHead } from 'components/Table/TableHead'
import useGetCustomersList from 'hooks/useGetCustomersList'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Service from 'services/action.service'
import DealService from 'services/deal.service'
import { useToastContext } from '../../context/ToastContext'
import { formatDate } from '../../helpers/date-helper'
import { getDataForPagination } from '../../helpers/pagination'
import useLocalStorage from '../../hooks/useLocalStorage'
import { CopyButton, CreateButton, DeleteButton, OpenButton } from '../Button/Buttons'
import { DropDown } from '../Common/Inputs'
import Loading from '../Common/Loading'
import SimpleModal from '../Common/SimpleModal'
import Paging from '../Paging'

const Index = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const [modal, setModal] = useState({ show: false })
  const { customersList, customersLoading } = useGetCustomersList()

  const entityGroupName = 'actions'
  const entityName = 'Versendungen'
  const notFound = `Keine Versendung gefunden`

  function errorMessage(error) {
    return `Technischer Fehler: ${error}`
  }

  function deleteSuccessMessage(objectId) {
    return `Die Versendung (ID: ${objectId}) wurde gelöscht.`
  }

  const [pageState, setPageState] = useState({
    actions: [],
    deals: [],
    totalResults: null,
  })

  const [selectedCustomer, setSelectedCustomer] = useLocalStorage('actions-selected-customer', null)
  const [selectedDeal, setSelectedDeal] = useLocalStorage('actions-selected-deal', null)
  const [selectedPageSize, setSelectedPageSize] = useLocalStorage('actions-selected-page-size', 20)
  const [selectedPage, setSelectedPage] = useLocalStorage('actions-selected-page', 1)

  useEffect(() => {
    getData()
  }, [selectedDeal, selectedCustomer, selectedPage, selectedPageSize])

  const getData = async () => {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Promise.all([
      Service.getPaging(selectedCustomer, selectedDeal, selectedPageSize),
      Service.get(selectedCustomer, selectedDeal, selectedPageSize, selectedPage),
      DealService.getList(selectedCustomer),
    ])
      .then(([pagingResult, actionsResult, dealsResult]) => {
        if (pagingResult == null || pagingResult.length <= 0) {
          navigate(`/${entityGroupName}/`)
          addToast(
            'Es wurden keine Kampagnen gefunden angelegt. Es muss mindestens ein Kunde existieren.',
            'error'
          )
        }

        setPageState((prev) => ({
          ...prev,
          actions: actionsResult,
          deals: dealsResult,
          isLoading: false,
          totalResults: pagingResult.count,
        }))
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  async function handleDelete(actionId) {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Service.deleteByObjectId(actionId)
      .then((response) => {
        addToast(deleteSuccessMessage(actionId), 'success')
        setModal({ show: false })
        getData()
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
      })
      .finally(() => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  function openDeleteModal(actionId) {
    setModal({
      show: true,
      title: `Lösche ${entityName}`,
      text: `Willst du die Versendung wirklich löschen?`,
      onCancelClicked: () => setModal({ show: false }),
      onOkayClicked: () => handleDelete(actionId),
      submitButton: 'Ja',
      backButton: 'Nein',
    })
  }

  async function copyAction(item) {
    await Promise.all([Service.copyAction(item.deal._id, item._id)])
      .then(([actionsResult]) => {
        getData()
      })
      .catch((error) => {
        addToast(errorMessage(error), 'error')
      })
      .finally(() => {})
  }

  if (pageState.isLoading || customersLoading) {
    return <Loading />
  } else {
    return (
      <>
        <ComponentWrapper>
          <div className="text-center">
            <CreateButton
              onClick={() => navigate(`/${entityGroupName}/create`)}
              title={`${entityName} anlegen`}
            />
          </div>
        </ComponentWrapper>
        <ComponentWrapper>
          <div className="flex">
            <div className="basis-1/3">
              <DropDown
                list={customersList}
                title="Filter Kunde"
                name="selectedCustomer"
                value={selectedCustomer}
                onChange={(event) => {
                  setSelectedDeal(null)
                  setSelectedPage(1)
                  setSelectedCustomer(event.target.value)
                }}
              />
            </div>
            <div className="basis-1/3">
              <DropDown
                list={pageState.deals}
                title="Filter Kampagne"
                name="deal"
                value={selectedDeal}
                disabled={!selectedCustomer}
                onChange={(event) => setSelectedDeal(event.target.value)}
              />
            </div>
            <div className="basis-1/3">
              <DropDown
                list={[
                  { _id: '20', name: '20' },
                  { _id: '50', name: '50' },
                  { _id: '100', name: '100' },
                ]}
                title="Anzahl Ergebnisse"
                name="selectedPageSize"
                value={selectedPageSize}
                onChange={(event) => setSelectedPageSize(event.target.value)}
              />
            </div>
          </div>
          <Paging
            key={1}
            data={getDataForPagination(
              pageState.totalResults,
              selectedPageSize,
              selectedPage,
              setSelectedPage
            )}
          />
          <Table>
            <TableHead>
              <TableHeaderRow>
                <TableCell>Kunde</TableCell>
                <TableCell>Kampagne</TableCell>
                <TableCell>Buchung</TableCell>
                <TableCell>Versand</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Erstellt/Bearbeitet</TableCell>
                <TableCell></TableCell>
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {typeof pageState.actions == 'undefined' ||
              pageState.actions == null ||
              pageState.actions.length <= 0 ? (
                <TableRow index={0}>
                  <TableCell colSpan={8} className="text-center">
                    {notFound}
                  </TableCell>
                </TableRow>
              ) : (
                pageState.actions.map((action, index) => (
                  <TableRow key={action._id} index={index}>
                    <TableCell>
                      {action.deal?.customer ? action.deal?.customer?.name : '- Gelöscht -'}
                    </TableCell>
                    <TableCell>{action.deal ? action.deal?.name : ' - Gelöscht -'}</TableCell>
                    <TableCell className="text-nowrap">
                      {action.deal?.bookingDate ? formatDate(action.deal?.bookingDate) : '-'}
                    </TableCell>
                    <TableCell className="text-nowrap">
                      {formatDate(new Date(action.date))} {action.time}
                    </TableCell>
                    <TableCell className="text-nowrap">{action.type}</TableCell>
                    <TableCell>
                      <StatusButton status={action.status} />
                    </TableCell>
                    <TableCell>
                      {formatDate(action.createdAt)} / {formatDate(action.lastChangedAt)}
                    </TableCell>
                    <TableCell>
                      {action.deal !== null && action.deal.customer !== null && (
                        <ActionIconWrapper>
                          <OpenButton
                            onClick={() => navigate(`/${entityGroupName}/${action._id}/update`)}
                          />
                          <CopyButton onClick={() => copyAction(action)} />
                          <DeleteButton onClick={() => openDeleteModal(action._id)} />
                        </ActionIconWrapper>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Paging
            key={2}
            data={getDataForPagination(
              pageState.totalResults,
              selectedPageSize,
              selectedPage,
              setSelectedPage
            )}
          />
        </ComponentWrapper>
        {modal.show && <SimpleModal modal={modal} />}
      </>
    )
  }
}

export default Index
