import { useToastContext } from 'context/ToastContext'
import { useEffect, useState } from 'react'
import AccountService from 'services/account.service'

const useAccountsList = () => {
  const [accountsList, setAccounts] = useState([])
  const [accountsLoading, setIsLoading] = useState(false)
  const addToast = useToastContext()

  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true)
      try {
        const result = await AccountService.getList()
        setAccounts(result)
      } catch (error) {
        addToast('Failed to fetch accounts', 'error')
      } finally {
        setIsLoading(false)
      }
    }

    fetchAccounts()
  }, [addToast])

  return { accountsList, accountsLoading }
}

export default useAccountsList
