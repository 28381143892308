import {
  BackButton,
  CancelButton,
  DeleteButton,
  EditButton,
  SaveButton,
} from 'components/Button/Buttons'
import { FileTable } from 'components/Common/FileTable'
import {
  DropDown,
  FileUpload,
  InputDate,
  InputNumber,
  InputText,
  InputTextArea,
} from 'components/Common/Inputs'
import SimpleModal from 'components/Common/SimpleModal'
import { ComponentWrapper } from 'components/Table/Table'
import { useToastContext } from 'context/ToastContext'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AccountService from 'services/account.service'
import CommonService from 'services/common.service'
import ContactService from 'services/contact.service'
import { getAllCustomers } from 'services/customer.service'
import Service from 'services/deal.service'

const entityGroupName = `deals`
const entityName = 'Kampagne'

export const DealForm = () => {
  const addToast = useToastContext()
  const { objectId } = useParams()
  const [pageState, setPageState] = useState({
    deal: Service.initializeObject(),
    customers: [],
    contacts: [],
    accounts: [],
    editMode: false,
    createMode: typeof objectId === 'undefined',
    newTemplate: null,
    newBlacklist: null,
    newGeneralFile: null,
    modal: { show: false },
  })

  const {
    editMode,
    createMode,
    modal,
    customers,
    contacts,
    accounts,
    deal,
    newTemplate,
    newBlacklist,
    newGeneralFile,
  } = pageState

  const navigate = useNavigate()

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setPageState((prev) => ({ ...prev, isLoading: true }))
    await Promise.all([
      getAllCustomers(),
      AccountService.get(),
      ContactService.get(),
      createMode ? Service.initializeObject() : Service.getByObjectId(objectId),
    ])
      .then(([customersResult, accountsResult, contactsResult, dealResult]) => {
        if (customersResult == null || customersResult.length <= 0) {
          navigate(`/${entityGroupName}/`)
          addToast(
            'Es sind keine Kunden angelegt. Es muss mindestens ein Kunde existieren.',
            'error'
          )
        }

        if (accountsResult == null || accountsResult.length <= 0) {
          navigate(`/${entityGroupName}/`)
          addToast(
            'Es sind keine Accounts angelegt. Es muss mindestens ein Account existieren.',
            'error'
          )
        }

        let tempContactList = [{ _id: null, name: 'Kein Hauptkontakt' }]
        if (contactsResult != null) {
          contactsResult.forEach((contact) => {
            tempContactList.push({
              _id: contact._id,
              name: contact.lastName + ' ' + contact.firstName + ' (' + contact.customer.name + ')',
            })
          })
        }

        if (createMode) {
          dealResult.customer = customersResult[0]._id
          dealResult.account = accountsResult[0]._id
        }

        setPageState((prev) => ({
          ...prev,
          customers: customersResult,
          accounts: accountsResult,
          contacts: tempContactList,
          deal: dealResult,
          isLoading: false,
        }))
      })
      .catch((error) => {
        addToast(CommonService.errorMessage(error), 'error')
        setPageState((prev) => ({ ...prev, isLoading: false }))
      })
  }

  const onChange = (event) => {
    const { name, value } = event.target
    switch (name) {
      default:
        setPageState((prevState) => ({
          ...prevState,
          deal: {
            ...prevState.deal,
            [name]: value,
          },
        }))
    }
  }

  async function handleOnSubmit() {
    setPageState((prev) => ({ ...prev, isLoading: true }))
    if (createMode) {
      await Service.create(deal)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, createMode), 'success')
          navigate(`/${entityGroupName}/${result._id}/update`)
        })
        .catch((error) => {
          addToast(CommonService.errorMessage(error), 'error')
        })
        .finally(() => {
          setPageState((prev) => ({ ...prev, isLoading: false }))
        })
    } else {
      await Service.update(deal)
        .then((result) => {
          addToast(CommonService.successMessage(entityName, createMode), 'success')
          setPageState((prev) => ({ ...prev, editMode: false }))
        })
        .catch((error) => {
          addToast(CommonService.errorMessage(error), 'error')
        })
        .finally(() => {
          setPageState((prev) => ({ ...prev, isLoading: false }))
          getData()
        })
    }
  }

  const onNewTemplateFile = (event) => {
    let file = event.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      let templateFiles = [{ name: file.name, base64: reader.result }]
      if (deal.templateFiles !== null) {
        templateFiles = deal.templateFiles.concat(templateFiles)
      }

      setPageState((prevState) => ({
        ...prevState,
        deal: {
          ...prevState.deal,
          templateFiles: templateFiles,
        },
        newTemplate: null,
      }))
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const onNewBlacklistFile = (event) => {
    let file = event.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      let blacklistFiles = [{ name: file.name, base64: reader.result }]
      if (deal.blacklistFiles !== null) {
        blacklistFiles = deal.blacklistFiles.concat(blacklistFiles)
      }

      setPageState((prevState) => ({
        ...prevState,
        deal: {
          ...prevState.deal,
          blacklistFiles: blacklistFiles,
        },
        newBlacklist: null,
      }))
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const onNewGeneralFile = (event) => {
    let file = event.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      let generalFiles = [{ name: file.name, base64: reader.result }]
      if (deal.generalFiles !== null) {
        generalFiles = deal.generalFiles.concat(generalFiles)
      }

      setPageState((prevState) => ({
        ...prevState,
        deal: {
          ...prevState.deal,
          generalFiles: generalFiles,
        },
        newGeneralFile: null,
      }))
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const onDeleteTemplateFile = (key) => {
    let templateFiles = deal.templateFiles
    templateFiles.splice(key, 1)

    setPageState((prevState) => ({
      ...prevState,
      deal: {
        ...prevState.deal,
        templateFiles: templateFiles,
      },
    }))
  }

  const onDeleteBlacklistFile = (key) => {
    let blacklistFiles = deal.blacklistFiles
    blacklistFiles.splice(key, 1)

    setPageState((prevState) => ({
      ...prevState,
      deal: {
        ...prevState.deal,
        blacklistFiles: blacklistFiles,
      },
    }))
  }

  const onDeleteGeneralFiles = (key) => {
    let generalFiles = deal.generalFiles
    generalFiles.splice(key, 1)

    setPageState((prevState) => ({
      ...prevState,
      deal: {
        ...prevState.deal,
        generalFiles: generalFiles,
      },
    }))
  }

  function deleteSuccessMessage(dealId) {
    return `Die Kampagne (ID: ${dealId}) wurde gelöscht.`
  }

  async function openDeleteModal(dealId) {
    setPageState((prevState) => ({
      ...prevState,
      modal: {
        ...prevState.modal,
        show: true,
        title: `Lösche Kampagne`,
        text: `Willst du die Kampagne wirklich löschen?`,
        onCancelClicked: () =>
          setPageState((prev) => ({ ...prev, modal: { ...prev.modal, show: false } })),
        onOkayClicked: () => handleDelete(dealId),
        submitButton: 'Ja',
        backButton: 'Nein',
      },
    }))
  }

  async function handleDelete(dealId) {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
    }))
    await Service.deleteByObjectId(dealId)
      .then((response) => {
        addToast(deleteSuccessMessage(dealId), 'success')
        setPageState((prev) => ({ ...prev, modal: { ...prev.modal, show: false } }))
        navigate(`/deals/`)
      })
      .catch((error) => {
        addToast(CommonService.errorMessage(error), 'error')
      })
      .finally(() => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  const enableSaveButton = () => {
    return deal.name && deal.customer && deal.bookingNumber && deal.bookingDate
  }

  const inputsDisabled = !editMode && !createMode

  return (
    <ComponentWrapper>
      <div className="flex gap-2">
        <div className="flex-1">
          <DropDown
            name="customer"
            title="Kunde"
            value={deal.customer}
            onChange={onChange}
            required={true}
            list={customers}
            disabled={inputsDisabled}
          />
          <InputText
            name="name"
            title="Name"
            value={deal.name}
            onChange={onChange}
            required={true}
            disabled={inputsDisabled}
          />
          <DropDown
            name="mainContact"
            title="Kontakt"
            value={deal.mainContact}
            onChange={onChange}
            required={false}
            list={contacts}
            disabled={inputsDisabled}
          />
        </div>
        <div className="flex-1">
          <InputDate
            name="bookingDate"
            title="Buchungs Datum"
            value={new Date(deal.bookingDate).toISOString().split('T')[0]}
            onChange={onChange}
            required={true}
            disabled={inputsDisabled}
          />
          <InputText
            name="bookingNumber"
            title="Buchungs/Projekt Nummer"
            value={deal.bookingNumber}
            onChange={onChange}
            required={true}
            disabled={inputsDisabled}
          />
          <InputNumber
            title="Buchungsmenge"
            name="bookedMails"
            value={deal.bookedMails}
            onChange={onChange}
            disabled={inputsDisabled}
            required={true}
          />
        </div>
      </div>
      <div className="flex gap-2">
        <DropDown
          name="account"
          title="Account"
          value={deal.account}
          onChange={onChange}
          required={true}
          list={accounts}
          disabled={inputsDisabled}
        />
        <InputNumber
          title="TKP €"
          name="tkp"
          value={deal.tkp}
          onChange={onChange}
          disabled={inputsDisabled}
          required={true}
        />
        <InputNumber
          title="CPL Gesamt €"
          name="cpl"
          value={deal.cpl}
          onChange={onChange}
          disabled={inputsDisabled}
        />
        <InputNumber
          title="CPO Gesamt €"
          name="cpo"
          value={deal.cpo}
          onChange={onChange}
          disabled={inputsDisabled}
        />
        <InputDate
          name="invoiceSent"
          title="Rechnungsdatum"
          value={
            deal.invoiceSent != null ? new Date(deal.invoiceSent).toISOString().split('T')[0] : null
          }
          onChange={onChange}
          required={false}
          disabled={inputsDisabled}
        />
      </div>
      <div className="flex gap-2">
        <div className="flex-1">
          <InputTextArea
            name="characteristics"
            title="Selektion"
            value={deal.characteristics}
            onChange={onChange}
            disabled={inputsDisabled}
          />
        </div>
        <div className="flex-1">
          <InputTextArea
            name="description"
            title="Beschreibung"
            value={deal.description}
            onChange={onChange}
            disabled={inputsDisabled}
          />
        </div>
      </div>
      <div className="flex gap-2 p-2">
        <div className="flex-1">
          {!inputsDisabled && (
            <FileUpload
              name="templateFiles"
              title="Werbemittel Datei hochladen"
              value={newTemplate}
              onChange={onNewTemplateFile}
              disabled={inputsDisabled}
            />
          )}
          <FileTable
            files={deal.templateFiles}
            onDeleteFile={onDeleteTemplateFile}
            showDeleteButton={editMode || createMode}
            title="Template Files"
          />
        </div>
        <div className="flex-1">
          {!inputsDisabled && (
            <FileUpload
              name="blacklistFiles"
              title="Blacklist Datei hochladen"
              value={newBlacklist}
              onChange={onNewBlacklistFile}
              disabled={inputsDisabled}
            />
          )}
          <FileTable
            files={deal.blacklistFiles}
            onDeleteFile={onDeleteBlacklistFile}
            showDeleteButton={editMode || createMode}
            title="Blacklist Files"
          />
        </div>
        <div className="flex-1">
          {!inputsDisabled && (
            <FileUpload
              name="generalFiles"
              title="General Datei hochladen"
              value={newGeneralFile}
              onChange={onNewGeneralFile}
              disabled={inputsDisabled}
            />
          )}
          <FileTable
            files={deal.generalFiles}
            onDeleteFile={onDeleteGeneralFiles}
            showDeleteButton={editMode || createMode}
            title="General Files"
          />
        </div>
      </div>
      <div className="flex justify-end gap-2">
        {!inputsDisabled ? (
          <>
            <CancelButton onClick={() => setPageState((prev) => ({ ...prev, editMode: false }))} />
            <SaveButton
              enableSaveButton={() => enableSaveButton()}
              handleOnSubmit={() => handleOnSubmit()}
            />
          </>
        ) : (
          <>
            <BackButton />
            <EditButton onClick={() => setPageState((prev) => ({ ...prev, editMode: true }))} />
          </>
        )}
        {!createMode && <DeleteButton onClick={() => openDeleteModal(deal._id)} />}
      </div>
      {modal.show === true && <SimpleModal modal={modal} />}
    </ComponentWrapper>
  )
}
