import Loading from 'components/Common/Loading'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CommonService from 'services/common.service'
import ContactService from 'services/contact.service'
import { getAllCustomers } from 'services/customer.service'
import { useToastContext } from '../../context/ToastContext'
import DetailFooter from '../Common/DetailFooter'
import { DropDown, InputEmail, InputPhone, InputText } from '../Common/Inputs'

const entityGroupName = 'contacts'
const entityName = 'Kontakt'

const ContactForm = () => {
  const addToast = useToastContext()
  const { objectId } = useParams()
  const navigate = useNavigate()

  const [pageState, setPageState] = useState({
    contact: ContactService.initContact,
    customerList: [],
    editMode: false,
    createMode: typeof objectId === 'undefined',
  })

  const { contact, editMode, createMode } = pageState

  const readOnly = !editMode && !createMode
  const safeButtonDisabled = !contact.lastName || !contact.customer || !contact.email

  useEffect(() => {
    const getData = async () => {
      try {
        const customers = await getAllCustomers()
        if (!customers || customers.length <= 0) {
          navigate(`/${entityGroupName}/`)
          addToast(
            'Es sind keine Kunden angelegt. Es muss mindestens ein Kunde existieren.',
            'error'
          )
          return
        }

        if (createMode) {
          setPageState((prevState) => ({
            ...prevState,
            contact: { ...prevState.contact, customer: customers[0]._id },
            customerList: customers,
          }))
        } else {
          const result = await ContactService.getByObjectId(objectId)
          setPageState((prevState) => ({
            ...prevState,
            contact: result,
            customerList: customers,
          }))
        }
      } catch (error) {
        addToast(CommonService.errorMessage(error), 'error')
      }
    }

    getData()
  }, [objectId, editMode])

  const onChange = (event) => {
    const { name, value } = event.target
    setPageState((prevState) => ({
      ...prevState,
      contact: {
        ...prevState.contact,
        [name]: value,
      },
    }))
  }

  const handleOnSubmit = async () => {
    try {
      if (createMode) {
        const result = await ContactService.create(contact)
        addToast(CommonService.successMessage(entityName, createMode), 'success')
        navigate(`/${entityGroupName}/${result._id}/update`)
      } else {
        await ContactService.update(contact)
        addToast(CommonService.successMessage(entityName, createMode), 'success')
        setPageState((prevState) => ({ ...prevState, editMode: false }))
      }
    } catch (error) {
      addToast(CommonService.errorMessage(error), 'error')
    }
  }

  if (!contact) {
    return <Loading />
  }

  return (
    <>
      <div className="flex gap-2 w-full">
        <div className="flex flex-col gap-2 flex-1">
          <DropDown
            name="customer"
            title="Kunde"
            value={contact.customer}
            onChange={onChange}
            required={true}
            list={pageState.customerList}
            disabled={!createMode} // Double check this logic
          />
          <InputText
            name="firstName"
            title="Vorname"
            value={contact.firstName}
            onChange={onChange}
            required={false}
            disabled={readOnly}
          />
          <InputText
            name="lastName"
            title="Nachname"
            value={contact.lastName}
            onChange={onChange}
            required={true}
            disabled={readOnly}
          />
        </div>
        <div className="flex flex-col gap-2 flex-1">
          <InputText
            name="description"
            title="Position"
            value={contact.description}
            onChange={onChange}
            required={false}
            disabled={readOnly}
          />
          <InputEmail
            name="email"
            title="Email"
            value={contact.email}
            onChange={onChange}
            required={true}
            disabled={readOnly}
          />
          <InputPhone
            name="phone"
            title="Telefon"
            value={contact.phone}
            onChange={onChange}
            required={false}
            disabled={readOnly}
          />
        </div>
      </div>
      <DetailFooter
        data={contact}
        showSaveButton={!readOnly}
        saveButtonDisabled={safeButtonDisabled}
        showDatabaseInfo={!createMode}
        navigate={navigate}
        handleOnSubmit={handleOnSubmit}
        handleOnEditClicked={() => setPageState((prevState) => ({ ...prevState, editMode: true }))}
        handleOnCancelClicked={() =>
          setPageState((prevState) => ({ ...prevState, editMode: false }))
        }
      />
    </>
  )
}

export default ContactForm
