import authHeader from '../helpers/auth-header'
import http from '../helpers/http-common'

export const initCustomer = () => {
  return {
    _id: '',
    createdBy: '',
    createdAt: '',
    lastChangedAt: '',
    lastChangedBy: '',

    customerNumber: 'MMG',
    mainContact: null,
    testEmailAddresses: '',
    name: 'Max Mustermann GmbH',
    email: '',
    phone: '',
    website: '',
    street: '',
    streetNumber: '',
    zipCode: '',
    city: '',
    state: '',
    country: '',
    taxNumber: '',
  }
}

export const getCustomerList = async () => {
  let list = [{ _id: '', name: '-' }]
  let result = await http.get(`customers`, { headers: authHeader() })
  result.forEach((item) => (item.name = item.name + ' (' + item.customerNumber + ')'))
  return list.concat(result)
}

export const getAllCustomers = async () => {
  return await http.get(`admin/customers`, { headers: authHeader() })
}

export const createCustomer = async (data) => {
  return http.post(`admin/customers`, data, { headers: authHeader() })
}

export const getCustomer = async (objectId) => {
  return await http.get(`admin/customers/${objectId}`, { headers: authHeader() })
}

export const updateCustomer = async (data) => {
  return await http.put(`admin/customers/${data._id}`, data, { headers: authHeader() })
}

export const deleteCustomer = async (objectId) => {
  return await http.delete(`admin/customers/${objectId}`, { headers: authHeader() })
}
