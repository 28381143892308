import { useToastContext } from 'context/ToastContext'
import { useEffect, useState } from 'react'
import { getCustomerList } from 'services/customer.service'

const useGetCustomersList = () => {
  const [customersList, setCustomerList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const addToast = useToastContext()

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const result = await getCustomerList()
        setCustomerList(result)
      } catch (error) {
        addToast('Failed to fetch customers', 'error')
      } finally {
        setIsLoading(false)
      }
    }

    fetch()
  }, [addToast])

  return { customersList, customersLoading: isLoading }
}

export default useGetCustomersList
