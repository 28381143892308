import authHeader from '../helpers/auth-header'
import http from '../helpers/http-common'

const mailList = [
  { name: 'A', _id: 'A' },
  { name: 'B', _id: 'B' },
]

const initializeObject = () => {
  return {
    _id: '',
    createdBy: '',
    createdAt: '',
    lastChangedAt: '',
    lastChangedBy: '',

    customer: null,
    mainContact: null,

    name: '',
    description: '',
    characteristics: '',

    bookingNumber: '',
    bookingDate: new Date().toISOString().split('T')[0],
    invoiceSent: null,
    bookedMails: '0',

    mailList: '',
    account: null,

    tkp: '0',
    cpl: '0',
    cpo: '0',

    templateFiles: [],
    blacklistFiles: [],
    generalFiles: [],
  }
}

async function getSimpleDealList(customerId) {
  let list = []

  let query = '?'
  if (customerId) {
    query += `customerId=${customerId}`
  }

  const result = await http.get(`deals/list${query}`, { headers: authHeader() })
  return list.concat(result)
}

async function getList(customerId) {
  let list = [{ _id: '', name: '-' }]
  const result = await this.getSimpleDealList(customerId)
  result.forEach((item) => (item.name = item.name + ' / ' + item.bookingNumber))
  return list.concat(result)
}

async function getPaging(customerId, accountId, pageSize) {
  let query = '?'

  if (customerId) {
    query += `customerId=${customerId}`
  }

  if (accountId) {
    query += `&accountId=${accountId}`
  }

  if (pageSize) {
    query += `&pageSize=${pageSize}`
  }

  return await http.get(`admin/deals/paging${query}`, { headers: authHeader() })
}

async function get(customerId, accountId, pageSize, page, searchQuery) {
  const params = new URLSearchParams()

  if (customerId) params.append('customerId', customerId)
  if (accountId) params.append('accountId', accountId)
  if (pageSize) params.append('pageSize', pageSize)
  if (page) params.append('page', page)
  if (searchQuery) params.append('searchQuery', searchQuery)

  return http.get(`deals?${params.toString()}`, { headers: authHeader() })
}

async function create(data) {
  return http.post(`admin/customers/${data.customer}/deals`, data, { headers: authHeader() })
}

async function getByObjectId(dealId) {
  return await http.get(`admin/deals/${dealId}`, { headers: authHeader() })
}

async function update(data) {
  return await http.put(`admin/deals/${data._id}`, data, { headers: authHeader() })
}

async function deleteByObjectId(dealId) {
  return await http.delete(`admin/deals/${dealId}`, { headers: authHeader() })
}

async function copyDeal(dealId) {
  return await http.get(`admin/deals/${dealId}/copy`, {
    headers: authHeader(),
  })
}

const service = {
  mailList,
  initializeObject,
  get,
  getList,
  getSimpleDealList,
  create,
  getByObjectId,
  update,
  deleteByObjectId,
  getPaging,
  copyDeal,
}

export default service
